'use strict';

angular.module('components.language',
    [
        'ngCookies',
        'gettext'
    ]
)

    .run(function($cookies, gettextCatalog) {

        var language = null;

        if ($cookies.get('language') !== undefined) {
            language = $cookies.get('language');
        } else {
            if ((window.navigator.userLanguage || window.navigator.language) === 'it') {
                language = 'it';
            } else {
                language = 'de';
            }
            $cookies.put('language', language);
        }

        gettextCatalog.setCurrentLanguage(language);
    })
    
    .directive('language', function($cookies, gettextCatalog) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'components/language.tpl.html',

            link: function ($scope) {
                $scope.language = function() {
                    return $cookies.get('language');
                };
                
                $scope.change = function(language) {
                    switch (language) {
                        case 'de':
                        case 'it':
                            break;

                        default:
                            return;
                    }

                    $cookies.put('language', language);
                    gettextCatalog.setCurrentLanguage(language);
                };
            }
        }
    });
