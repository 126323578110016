'use strict';

angular.module('services.variables',
    [

    ]
)

.factory("variables", function() {
    return {
        licence_number: null,
        registration_code: null,
        image: null
    };
});
