'use strict';

angular.module('pages.rooting',
    [
        'env'
    ]
)

    .controller('RootingController', ['$scope','$routeParams', '$location', 'env', function($scope, $routeParams, $location, env) {


        $scope.reason = $routeParams.reason;
        $scope.manufacturer = $routeParams.manufacturer;

        $scope.help = function() {
            $location.path($scope.subDirectory + '/rooting');
        };
    }]);
