'use strict';

angular.module('steps.step1',
    [
        'components.app-links',
        'gettext',
        'ngCookies',
        'services.registration'
    ]
)

    .directive('step1', function($cookies, RegistrationService) {
        return {
            restrict: 'E',
            scope: {
                next: '=',
                cancel: '=',
                type: '=',
                image: '=',
                licenceNumber: '=',
                registrationCode: '='
            },
            templateUrl: 'steps/step1.tpl.html',

            link: function ($scope) {
                $scope.otp = '';
                $scope.generalError = false;
                $scope.registrationError = 0;
                $scope.button = {};

                var successCallback = function(response) {
                    $scope.registrationError = response['esito'];
                    $scope.generalError = false;

                    if($scope.registrationError === '000') {
                        $scope.next(
                            response['image']
                        );
                    } else {
                        switch ($scope.registrationError) {
                            case '133':
                                break;

                            default:
                                $scope.generalError = true;
                        }

                        $scope.button.disabled = false;
                    }
                };

                var failureCallback = function(response) {
                    $scope.generalError = true;
                    $scope.registrationError = 0;
                    $scope.button.disabled = false;
                };

                $scope.onEnterOtp = function() {
                    if ($scope.otp) {
                        var spaceAtChar = [5,9];
                        var stringLength = $scope.otp.length;
                        var matchedChar = inArray(stringLength, spaceAtChar);
                        var enableSubmit = 13;

                        if (matchedChar) {
                            if ($scope.otp.charAt(stringLength) !== ' ') {
                                var splitAt = stringLength - 1;
                                var firstPart = $scope.otp.substr(0, splitAt);
                                var secondPart = $scope.otp.substr(splitAt);
                                $scope.otp = firstPart + ' ' + secondPart;
                            }
                        }

                        if ((stringLength > 8) && ($scope.otp.split(' ').length-1 != 2)) {
                            spaceAtChar.forEach(function(spaceAt, index){
                                if (!index) {
                                    $scope.otp = $scope.otp.replace(' ', '');
                                }
                                if ($scope.otp.charAt(spaceAt) !== ' ') {
                                    var splitAt = spaceAt - 1;
                                    var firstPart = $scope.otp.substr(0, splitAt);
                                    var secondPart = $scope.otp.substr(splitAt);
                                    $scope.otp = firstPart + ' ' + secondPart;
                                }
                            })
                            stringLength = $scope.otp.length;
                        }

                        if (stringLength == enableSubmit) {
                            $scope.button.disabled = false;
                        } else {
                            $scope.button.disabled = true;
                        }
                    }
                };

                function inArray(needle, haystack) {
                    var length = haystack.length;
                    for(var i = 0; i < length; i++) {
                        if(haystack[i] == needle) {
                            return true;
                        }
                    }
                    return false;
                }

                $scope.ok = function() {
                    $scope.button.disabled = true;
                    if($scope.registrationForm.$valid) {
                        RegistrationService.otp1(
                            $scope.licenceNumber,
                            $scope.registrationCode,
                            $scope.otp.replace(/ /g,''),
                            successCallback,
                            failureCallback
                        );
                    }
                };

            }
        }
    });
