'use strict';

angular.module('steps.step2',
    [
        'gettext',
        'ngCookies',
        'services.registration'
    ]
)

    .directive('step2', function($cookies, RegistrationService) {
        return {
            restrict: 'E',
            scope: {
                next: '=',
                cancel: '=',
                type: '=',
                image: '=',
                licenceNumber: '=',
                registrationCode: '='
            },
            templateUrl: 'steps/step2.tpl.html',

            link: function ($scope) {
                $scope.otp = '';
                $scope.generalError = false;
                $scope.registrationError = 0;
                $scope.button = {};

                var successCallback = function(response) {

                    $scope.registrationError = response['esito'];
                     if($scope.registrationError === '000') {
                         $scope.next();
                     } else {
                         failureCallback(response);
                     }

                    // use this on test system to get through process: ACTIVATE VALIDATION FOR DOUBLE REGISTRATION
                    // js:test fails with this kind of validation
                    /*
                    if ($scope.registrationError === '000' || $scope.registrationError === '103') {
                        console.log('000 or 103');
                        console.log($scope.generalError);
                        $scope.next();
                    } else {
                        failureCallback(response);
                    }
                    */
                };

                var failureCallback = function(response) {
                    $scope.generalError = true;
                    $scope.button.disabled = false;
                };

                $scope.onEnterOtp = function() {
                    if ($scope.otp) {
                        var spaceAtChar = [4,7];
                        var stringLength = $scope.otp.length;
                        var matchedChar = inArray(stringLength, spaceAtChar);
                        var enableSubmit = 10;

                        if (matchedChar) {
                            if ($scope.otp.charAt(stringLength) !== ' ') {
                                var splitAt = stringLength - 1;
                                var firstPart = $scope.otp.substr(0, splitAt);
                                var secondPart = $scope.otp.substr(splitAt);
                                $scope.otp = firstPart + ' ' + secondPart;
                            }
                        }

                        if ((stringLength > 6) && ($scope.otp.split(' ').length-1 != 2)) {
                            spaceAtChar.forEach(function(spaceAt, index){
                                if (!index) {
                                    $scope.otp = $scope.otp.replace(' ', '');
                                }
                                if ($scope.otp.charAt(spaceAt) !== ' ') {
                                    var splitAt = spaceAt - 1;
                                    var firstPart = $scope.otp.substr(0, splitAt);
                                    var secondPart = $scope.otp.substr(splitAt);
                                    $scope.otp = firstPart + ' ' + secondPart;
                                }
                            })
                            stringLength = $scope.otp.length;
                        }
                    }

                    if (stringLength == enableSubmit) {
                        $scope.button.disabled = false;
                    } else {
                        $scope.button.disabled = true;
                    }
                };

                function inArray(needle, haystack) {
                    var length = haystack.length;
                    for(var i = 0; i < length; i++) {
                        if(haystack[i] == needle) {
                            return true;
                        }
                    }
                    return false;
                }

                $scope.ok = function() {
                    $scope.button.disabled = true;
                    if($scope.registrationForm.$valid) {
                        RegistrationService.otp2(
                            $scope.licenceNumber,
                            $scope.registrationCode,
                            $scope.otp.replace(/ /g,''),
                            successCallback,
                            failureCallback
                        );
                    }
                };
            }
        }
    });
