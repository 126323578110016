'use strict';

angular.module('pages.registration',
    [
        'env',
        'services.variables',
        'steps.step1',
        'steps.step2',
        'steps.step3',
        'components.pagination'
    ]
)

    .controller('RegistrationController', function($scope, $location, variables, env) {

        if(variables.image !== null) {

            $scope.step = 1;
            $scope.licence_number = variables.licence_number;
            $scope.registration_code = variables.registration_code;
            $scope.image = variables.image;
            $scope.subDirectory = env['subDirectory'];
            $scope.type = variables.type;

            variables.licence_number = null;
            variables.registration_code = null;
            variables.image = null;
            variables.type = null;

        } else {
            $location.path(env['subDirectory'] + '/');
        }

        $scope.next = function(image) {
            $scope.image = image;
            $scope.step++;
        };

        $scope.cancel = function() {
            $location.path(env['subDirectory'] + '/');
        };
    });
