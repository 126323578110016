'use strict';

angular.module('layout',
    [
        'env',
        'components.language',
        'gettext'
    ]
)
    
    .directive('layout', function(env) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {},
            templateUrl: 'layout.tpl.html',

            link: function ($scope) {
                $scope.subDirectory = env['subDirectory'];
            }
        }
    });
