'use strict';

angular.module('pages.home',
    [
        'env',
        'services.variables',
        'steps.init'
    ]
)

    .controller('HomeController', function($scope, $location, variables, env) {
        variables.licence_number = null;
        variables.registration_code = null;
        variables.image = null;
        variables.type = null;

        $scope.subDirectory = env['subDirectory'];

        $scope.next = function(image, licence_number, registration_code, type) {
            variables.image = image;
            variables.licence_number = licence_number;
            variables.registration_code = registration_code;
            $location.path($scope.subDirectory + '/wizard');
            variables.type = type;
            
        };
    });
