'use strict';

angular.module('routes',
    [
        'ngCookies',
        'ngRoute',
        'env',
        'gettext',
        'pages.registration',
        'pages.help',
        'pages.home',
        'pages.rooting'
    ]
)

    .config(function($routeProvider, $locationProvider, env) {
        $routeProvider
            .when(env['subDirectory'] + '/', {
                templateUrl: 'pages/home.tpl.html',
                controller: 'HomeController'
            })
            .when(env['subDirectory'] + '/wizard', {
                templateUrl: 'pages/registration.tpl.html',
                controller: 'RegistrationController'
            })
            .when(env['subDirectory'] + '/help', {
                templateUrl: 'pages/help.tpl.html',
                controller: 'HelpController'
            })
            .when(env['subDirectory'] + '/rooting', {
                templateUrl: 'pages/rooting.tpl.html',
                controller: 'RootingController'
            })
            .otherwise({
                redirectTo: env['subDirectory'] + '/'
            });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    })

    .run(function($rootScope, $cookies, $location, gettextCatalog) {
        $rootScope.$on("$routeChangeStart", function(event, next, current) {

            if (typeof next.params !== "undefined" && typeof next.params.language !== "undefined") {
                var language = next.params.language;

                switch (language) {
                    case 'de':
                    case 'it':
                        break;

                    default:
                        return;
                }

                $cookies.put('language', language);
                gettextCatalog.setCurrentLanguage(language);

                $location.search('language', null);
            }
        });
    });
