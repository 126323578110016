'use strict';

angular.module('components.pagination',
    [

    ]
)
    
    .directive('pagination', function() {
        return {
        
            restrict: 'E',
            scope: {
                step: '='
            },
            templateUrl: 'components/pagination.tpl.html',

            link: function ($scope) {

            }
        }
    });
