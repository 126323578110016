'use strict';

angular.module('app',
    [
        'templates',
        'env',
        'routes',
        'layout'
    ]
);
