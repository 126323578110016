'use strict';

angular.module('steps.step3',
    [
        'env',
        'gettext'
    ]
)

    .directive('step3', function(env) {
        return {
            restrict: 'E',
            scope: {
                next: '=',
                cancel: '=',
                type: '='
            },
            templateUrl: 'steps/step3.tpl.html',

            link: function ($scope) {
                $scope.subDirectory = env['subDirectory'];
            }
        }
    });
