'use strict';

angular.module('pages.help',
    [
        'env'
    ]
)

    .controller('HelpController', function($scope, $location, env) {

        $scope.subDirectory = env['subDirectory'];

        $scope.help = function() {
            $location.path($scope.subDirectory + '/help');
        };
    });
