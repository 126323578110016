'use strict';

angular.module('components.app-links',
    [
        'env'
    ]
)

    .directive('appLinks', function(env) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'components/app-links.tpl.html',

            link: function ($scope) {
                $scope.subDirectory = env['subDirectory'];
            }
        }
    });
