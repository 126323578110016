'use strict';

angular.module('services.registration',
    [
        'env'
    ]
)

    .service('RegistrationService', function($http, env) {

        function generateRandomString(length) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for( var i=0; i < length; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }
        
        var traceId = generateRandomString(30);
        console.log('Trace ID: ' + traceId);


        function init(licence_number, registration_code, successCallback, failureCallback) {

            $http({
                method: 'PATCH',
                url: env['apiUrl'] + 'licenses/' + licence_number + '/step1',
                headers: {
                    'traceId': traceId
                },
                data: {
                    'registrationCode': registration_code
                }
            }).then(function(response) {
                // success callback
                successCallback(response['data']);
            }, function(response) {
                // error callback
                failureCallback(response);
            });
        }

        function otp1(licence_number, registration_code, otp, successCallback, failureCallback) {

            $http({
                method: 'PATCH',
                url: env['apiUrl'] + 'licenses/' + licence_number + '/step2',
                headers: {
                    'traceId': traceId
                },
                data: {
                    'registrationCode': registration_code,
                    'otp': otp
                }
            }).then(function(response) {
                // success callback
                successCallback(response['data']);
            }, function(response) {
                // error callback
                failureCallback(response);
            });
        }

        function otp2(licence_number, registration_code, otp, successCallback, failureCallback) {

            $http({
                method: 'PATCH',
                url:env['apiUrl'] + 'licenses/' + licence_number + '/step3',
                headers: {
                    'traceId': traceId
                },
                data: {
                    'registrationCode': registration_code,
                    'otp': otp
                }
            }).then(function(response) {
                // success callback
                successCallback(response['data']);
            }, function(response) {
                // error callback
                failureCallback(response);
            });
        }

        return {
            'init': init,
            'otp1': otp1,
            'otp2': otp2
        };
    });
