'use strict';

angular.module('steps.init',
    [
        'env',
        'components.app-links',
        'services.registration',
        'gettext'
    ]
)

    .directive('init', function($location, RegistrationService, env) {
        return {
            restrict: 'E',
            scope: {
                next: '='
            },
            templateUrl: 'steps/init.tpl.html',

            link: function ($scope) {
                $scope.subDirectory = env['subDirectory'];
                $scope.fields = {
                    'licence_number': '',
                    'registration_code': ''
                };
                $scope.generalError = false;
                $scope.registrationError = 0;
                $scope.button = {};

                var successCallback = function(response) {

                    var type = response['tipoSupporto'] == 1 ? "smartphone" : "digipass";

                    $scope.registrationError = response['esito'];
                    $scope.generalError = false;

                    if ($scope.registrationError === '000') {
                        $scope.next(
                            response['image'],
                            $scope.fields['licence_number'],
                            $scope.fields['registration_code'],
                            type
                        );
                    } else {
                        switch ($scope.registrationError) {
                            case '130':
                            case '998':
                            case '999':
                                break;

                            default:
                                $scope.generalError = true;
                        }

                        $scope.button.disabled = false;
                    }
                };

                var failureCallback = function(response) {
                    $scope.generalError = true;
                    $scope.registrationError = 0;
                    $scope.button.disabled = false;
                };

                $scope.register = function() {
                    $scope.button.disabled = true;

                    if($scope.registrationForm.$valid) {
                        RegistrationService.init(
                            $scope.fields['licence_number'],
                            $scope.fields['registration_code'],
                            successCallback,
                            failureCallback
                        );
                    }
                };
            }
        }
    });
